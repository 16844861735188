nav ul {
    margin: 0 0 20px 0;
    padding: 0;
    list-style: none;
    text-align: right
}

nav ul li {
    display: inline-block;
    margin: 0 20px 20px 20px;
}

nav ul li button {
    cursor: pointer;
    color: var(--blue);
    background-color: transparent;
    border: none;
    font-size: 1em;
    font-weight: 400;
}

nav ul li:hover {
    border-bottom: 2px solid var(--blue);
}

nav ul li.active {
    border-bottom: 2px solid var(--green);
}

nav ul li.active button {
    color: var(--green);
}

nav img {
    border-radius: 10px;
    max-width: 100%;
}

@media (max-width: 600px) {
    nav img { 
        max-width: 50%;
    }
}

@media (max-width: 480px) {
    nav ul li {
        margin: 0 5px 20px 5px;
    }
}

@media (max-width: 400px) {
    nav ul {
        text-align: center;
    }

    nav ul li button {
        font-size: 0.9em;
    }
}
