ul.projects {
    margin: 40px 0 0 0;
    padding: 0;
    list-style: none;
}

ul.projects li {
    margin: 0 20px 20px 20px;
    padding: 5px 25px;
    background-color: var(--blue-light);
    color: var(--black);
    border-radius: 10px;
    display: inline-flex;
    align-items: center;
}

ul.projects li a {
    cursor: pointer;
    color: var(--blue);
}

ul.projects li .icon {
    width: 32px;
    height: 32px;
    margin-left: 5px;
}
