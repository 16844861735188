:root {
  --white: #EDE5F4;
  --black: #303030;
  --blue: #00BAFF;
  --blue-med: #0D6986;
  --blue-dark: #07485B;
  --blue-light: #8EC7D2;
  --pink: #FF00A9;
  --green: #baff00;
}

html,
body {
  font-family: 'Raleway', sans-serif;
  background-color: var(--blue-dark);
  background-color: var(--black);
  color: var(--white);
  text-align: center;
  padding: 10px;
}

h1 {
  color: var(--green);
}

h2 ,h3, h4, h5, h6 {
  color: var(--blue);
}

h1 {
  font-size: 3em;
  font-weight: 700;
}

h2 {
  font-size: 2.5em;
  font-weight: 400;
}

p {
  font-size: 1.5em;
  font-weight: 400;
}

a {
  color: var(--pink);
}

a:hover {
  color: var(--blue);
}

.main-content {
  width: 80%;
  max-width: 1440px;
  margin: auto;
}

@media (max-width: 600px) {
  h1 {
    font-size: 2.5em;
  }

  h2 {
    font-size: 2.25em;
  }

  p {
    font-size: 1.25em;
  }

  .main-content {
    width: 95%;
  }
}
